<template>
  <div>
    <el-form ref="queryForm" inline :model="queryForm" :rules="formRules">
      <el-form-item label="数据筛选维度">
        <el-radio-group v-model="dataQueryType" @change="handleTabClick">
          <el-radio label="style">Style</el-radio>
          <el-radio label="sku">Sku</el-radio>
        </el-radio-group>
      </el-form-item>
      <br>
      <el-form-item label="采购订单号">
        <el-input v-model="queryForm.purchaseOrderCode" :placeholder="$t('page.inputPlaceholder')" />
      </el-form-item>
      <el-form-item label="采购计划单号">
        <el-input v-model="queryForm.purchasePlanCode" :placeholder="$t('page.inputPlaceholder')" />
      </el-form-item>
      <el-form-item label="创建人">
        <el-input v-model="queryForm.createByName" :placeholder="$t('page.inputPlaceholder')" />
      </el-form-item>
      <el-form-item label="状态">
        <CommonSelect
          v-model="queryForm.orderStatusList"
          clearable
          multiple
          :select-options="_getAllCommodityDict('PURCHASE_ORDER_STATUS')"
          :configuration="optionsConfig"
        />
      </el-form-item>
      <el-form-item label="供应商" :class="$i18n.locale">
        <el-select
          v-model="queryForm.vendorIdList"
          multiple
          filterable
          clearable
          style="min-width:280px;"
          :placeholder="$t('page.selectPlaceholder')"
        >
          <el-option
            v-for="item in vendorOptions"
            :key="item.id"
            :label="item.vendorName"
            :value="item.id"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="BU">
        <CommonSelect
          v-model="queryForm.buNameList"
          api-key="buList"
          clearable
          multiple
        />
      </el-form-item>
      <el-form-item label="Style">
        <!-- <el-select
          v-model="queryForm.styleNameList"
          multiple
          filterable
          clearable
          style="min-width:280px;"
          :placeholder="$t('page.selectPlaceholder')"
        >
          <el-option
            v-for="item in styleOptions"
            :key="item.id"
            :label="item.styleName"
            :value="item.id"
          />
        </el-select> -->
        <CommonSelect
          v-model="queryForm.styleNameListObj"
          api-key="styleList"
          :configuration="{
            valueKey: 'styleName',
            key: 'id',
            label: 'styleName'
          }"
          clearable
          multiple
          @change="styleChange"
        />
      </el-form-item>
      <el-form-item v-if="dataQueryType==='sku'" label="Color">
        <CommonSelect
          v-model="queryForm.colorId"
          api-key="colorList"
          clearable
          ganged
          :params="{
            styleId: queryForm.styleNameListId
          }"
        />
      </el-form-item>
      <el-form-item v-if="dataQueryType==='sku'" label="Size">
        <CommonSelect
          v-model="queryForm.sizeId"
          api-key="sizeList"
          clearable
          ganged
          :params="{
            styleId: queryForm.styleNameListId
          }"
        />
      </el-form-item>
      <el-form-item label="SKU">
        <el-input
          v-model="queryForm.skuList"
          placeholder="多个Sku使用换行符分割"
          type="textarea"
          :disabled="dataQueryType==='style'"
          :rows="2"
        />
      </el-form-item>
      <el-form-item label="季节">
        <CommonSelect
          v-model="queryForm.season"
          :select-options="_getAllCommodityDict('SEASON')"
          :configuration="optionsConfig"
        />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker
          v-model="queryForm.value"
          value-format="yyyy-MM-dd"
          type="daterange"
          range-separator="-"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        />
      </el-form-item>
      <el-form-item label="平台" prop="sellPlatformId">
        <CommonSelect
          v-model="queryForm.sellPlatformId"
          api-key="platformList"
          clearable
          :configuration="{ key: 'id', label: 'platformName', value: 'id'}"
          @responseData="val=>platformList=val"
          @change="queryForm.siteName=''"
        />
      </el-form-item>
      <el-form-item label="站点" prop="siteName">
        <CommonSelect
          v-model="queryForm.siteName"
          clearable
          ganged
          :params="{sellPlatformId:queryForm.sellPlatformId}"
          api-key="siteList"
        />
      </el-form-item>
      <el-form-item class="ml-4">
        <el-button :loading="tableLoading" type="primary" @click="handleDefaultQuery">搜索</el-button>
        <el-button @click="handleQueryFormReset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row :span="24" class="mb-3">
      <el-col :span="24">
        <ImportButton export-key="purchase_order_report" :params="exportParameter" :btn-type="'primary'" />
      </el-col>
    </el-row>
    <Table
      :table-data="tableData"
      :max-height="'600px'"
      :columns="purchaseOrderTableColumns"
      :check="true"
      :loading="tableLoading"
      :page-obj="pager"
      @handleSelectionChange="val => {multipleSelectionTable=val}"
    >
      <el-table-column v-if="dataQueryType==='sku'" slot="color" label="Color" align="center" min-width="100">
        <template slot-scope="scope">{{ scope.row.color }}</template>
      </el-table-column>
      <el-table-column v-if="dataQueryType==='sku'" slot="size" label="Size" align="center" min-width="100">
        <template slot-scope="scope">{{ scope.row.size }}</template>
      </el-table-column>
      <el-table-column v-if="dataQueryType==='sku'" slot="sku" label="SKU" align="left" min-width="180">
        <template slot-scope="scope">{{ scope.row.sku }}</template>
      </el-table-column>
      <el-table-column slot="delay" label="未准交原因" align="center" min-width="180">
        <template slot-scope="scope">
          <span v-if="scope.row.deliveryDelayI18 !== 'null'">{{ scope.row.deliveryDelayI18 }}</span>
        </template>
      </el-table-column>
    </Table>
    <Paging :pager="pager" @pagination="pagerUpdate" />
  </div>
</template>

<script>
import CommonSelect from '@/components/CommonSelect'
import Paging from '@/components/Pagination'
import { purchaseOrderTableColumns } from '@/constant/tablecolumns'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { getPurchaseOrderReportByStyle, getPurchaseOrderReportBySku, queryVendorList } from '@/api/scm-api'
import { omit } from 'lodash'
import Table from '@/components/Table'
import ImportButton from '@/components/ExportFile'

export default {
  components: { CommonSelect, Paging, Table, ImportButton },
  mixins: [commodityInfoDict],

  data() {
    var validateSellPlatformId = (rule, value, callback) => {
      this.$refs.queryForm.validateField('siteName')
      callback()
    }
    var validateSiteName = (rule, value, callback) => {
      if (this.queryForm.sellPlatformId && !value) {
        callback(new Error('请选择站点'))
      } else {
        callback()
      }
    }
    return {
      purchaseOrderTableColumns,
      tableData: [],
      pager: {
        current: 1,
        size: 20,
        total: 0
      },
      queryForm: { value: [], siteName: '', skuList: '', season: '', colorId: '', sizeId: '' },
      dataQueryType: 'style',
      platformList: [],
      vendorOptions: [],
      tableLoading: false,
      optionsConfig: { key: 'val', label: 'label', value: 'val' },
      multipleSelectionTable: [],
      formRules: {
        sellPlatformId: { validator: validateSellPlatformId, trigger: ['change', 'blur'] },
        siteName: { validator: validateSiteName, trigger: ['change', 'blur'] }
      }
    }
  },
  computed: {
    queryParams() {
      const { value = [], sellPlatformId, skuList = '', vendorIdList = [] } = this.queryForm
      const list = skuList.replaceAll('\n', ',')
      const platformName = this.platformList.find(item => item.id === sellPlatformId)?.platformName
      const [createBeginDate, createEndDate] = value || []
      return Object.assign({}, { dataQueryType: this.dataQueryType },
        omit(this.queryForm, ['value', 'sellPlatformId', 'styleNameListObj', 'styleNameListId']),
        { createBeginDate, createEndDate, platformName, vendorIdList: vendorIdList?.toString() || '' }, this.pager,
        { skuList: list && list.split(',').filter(item => item) || [] })
    },
    exportParameter() {
      const idsList = []
      this.multipleSelectionTable.map(item => idsList.push(item.idStr))
      return Object.assign({}, this.queryParams, { ids: idsList.join(','), vendorIdList: this.queryForm.vendorIdList })
    }
  },
  mounted() {
    this.handleDefaultQuery()
    this._queryVendorList()
  },
  methods: {
    // 供应商
    async _queryVendorList() {
      const { datas } = await queryVendorList()
      this.vendorOptions = datas
    },
    handleTabClick(val) {
      this.queryForm.skuList = ''
      this.handleQuery()
    },
    handleQueryFormReset() {
      this.queryForm = this.$options.data().queryForm
      this.handleQuery()
    },
    handleDefaultQuery() {
      this.$refs.queryForm.validate((valid, obj) => {
        if (valid) {
          this.pager.current = 1
          this.handleQuery()
        } else {
          return
        }
      })
    },
    styleChange(value) {
      // if (!value || (Array.isArray(value) && !value.length)) {
      // }
      this.queryForm.colorId = ''
      this.queryForm.sizeId = ''
      const arr = []
      const data = []
      Array.isArray(value) && value.map(v => {
        arr.push(v.styleName)
        data.push(v.id)
      })
      this.queryForm.styleNameList = arr
      this.queryForm.styleNameListId = data
    },
    async handleQuery() {
      try {
        this.tableLoading = true
        if (this.dataQueryType === 'style') {
          const { datas: { pager, records }} = await getPurchaseOrderReportByStyle(this.queryParams)
          this.pager = pager
          this.tableData = records
        } else {
          const { datas: { pager, records }} = await getPurchaseOrderReportBySku(this.queryParams)
          this.pager = pager
          this.tableData = records
        }
      } finally {
        this.tableLoading = false
      }
    },
    pagerUpdate(val) {
      this.pager = val
      this.handleQuery()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
